*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
header{
    
}

.banner{
    background-image: url("../src/images/banner-6.png");
    height: 600px;
    width: 100%;
    background-position: center;
}
.banner h1, p, button{
    margin-top: 100px;
    color: white;
}
.banner h1{
    font-size: 35px;
}
.banner p{
    color: white !important;
}

/*** Service ***/
.service-item {
    position: relative;
    padding: 45px 30px;
    background: #FFFFFF;
    transition: .5s;
    margin: 10px;
}
.service:hover h1 {
    color: white;
}
.service h1{
    color: black;
}
.service p{
    color: black;
}
.service:hover p{
    color: white;
}
.service-item:hover {
    background: var(--primary);
}

.service-item .service-icon {
    margin: 0 auto 20px auto;
    width: 90px;
    height: 90px;
    color: var(--primary);
    background: var(--light);
    transition: .5s;
}

.service-item:hover .service-icon {
    background: #FFFFFF;
}

.service-item h5,
.service-item p {
    transition: .5s;
}

.service-item:hover h5,
.service-item:hover p {
    color: #FFFFFF;
}

.service-item a.btn {
    position: relative;
    display: flex;
    color: var(--primary);
    transition: .5s;
    z-index: 1;
}
.faq div{
    color: black !important;
}
.service-item:hover a.btn {
    color: var(--primary);
}

.service-item a.btn::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    top: 0;
    left: 0;
    border-radius: 35px;
    background: var(--light);
    transition: .5s;
    z-index: -1;
}

.service-item:hover a.btn::before {
    width: 100%;
    background: var(--light);
}

.about{
    background-image: url(../src/images/banner-3.png);
    height: 500px;
    background-position: center;

}
.title{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.title h1{
    margin-top: 150px;
    font-size: 40px;
    font-weight: bold;
}
.title p{
    width: 700px;
    text-align: center;
}
.services{
    background-image: url(../src/images/banner-3.png);
    height: 500px;
    background-position: center;

}
.title{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.title h1{
    margin-top: 150px;
    font-size: 40px;
    font-weight: bold;
}
.title p{
    width: 700px;
    text-align: center;
}

.computing{
    background-image: url(../src/images/banner-5.png);
    height: 500px;
    background-position: center;
}
.consulting{
    background-image: url(../src/images/banner-5.png);
    height: 500px;
    background-position: center;
}
.hosting{
    background-image: url(../src/images/banner-5.png);
    height: 500px;
    background-position: center;
}
.maintenance{
    background-image: url(../src/images/banner-5.png);
    height: 500px;
    background-position: center;
}
.network{
    background-image: url(../src/images/banner-5.png);
    height: 500px;
    background-position: center;
}
.programming{
    background-image: url(../src/images/banner-5.png);
    height: 500px;
    background-position: center;
}
.software{
    background-image: url(../src/images/banner-5.png);
    height: 500px;
    background-position: center;
}
.computing .text-content p{
    color: black !important;
    margin: 10px;
}
.computing .text-content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.computing .text-content div h1{
    font-size: 30px;
    font-weight: bold;
    color: #BC3424;
}

.computing .text-content div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .service-text{
    margin-top: -20px;
} */

@media screen and (max-width: 1000px){
    .title p{
        width: 500px;
        text-align: center;
    }
}

@media screen and (max-width: 700px){
    .title p{
        width: 300px;
        text-align: center;
    }
    .service-text{
        margin-top: 0px;
    }
}

@media screen and (max-width: 500px){
    .contact-us-btn {
        display: none;
    }
}
